import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";


createInertiaApp({
    title: (title) => `${title} ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue);

        Sentry.init({
            app,
            dsn: "https://3e2ebe3752204b1056b3c1519f6c5cdf@o4505207757537280.ingest.us.sentry.io/4509082995195904",
            integrations: [],
            tracesSampleRate: 1.0, // Optional: enables performance monitoring
        });

        app.mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
